  <!-- Pricing start -->
  <section class="section bg-light" id="pricing">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="text-center mb-5">
            <h5 class="text-primary text-uppercase small-title">Planes</h5>
            <h4 class="mb-3">Elije tu plan</h4>
            <!-- <p>It will be as simple as occidental in fact, it will be Occidental.</p> -->
          </div>
        </div>
      </div>
      <!-- end row -->

      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-4">
            <ul class="nav nav-pills pricing-nav-tabs mb-4">
              <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active': !mostrarAnuales}" role="button" (click)="mostrarAnuales=false">Mensual</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active': mostrarAnuales}" role="button" (click)="mostrarAnuales=true">Anual</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4" *ngFor="let item of pricingData">
          <div class="pricing-plan card text-center">
            <div class="card-body p-4">
              <h5 class="mt-2 mb-5">{{item.title}}</h5>
              <h1 class="mb-5" *ngIf="item.price > 0 && !mostrarAnuales"><sup><small>$</small></sup>{{item.price}}/ <span class="font-16">Mes</span></h1>
              <h1 class="mb-5" *ngIf="item.anualprice > 0 && mostrarAnuales"><sup><small>$</small></sup>{{item.anualprice}}/ <span class="font-16">Anual</span></h1>
              
              <h1 class="mb-5" *ngIf="item.priceoferta > 0 && !mostrarAnuales" style="color: red;"><sup><small>$</small></sup>{{item.priceoferta}}/ <span class="font-16">Mes</span></h1>
              <h1 class="mb-5" *ngIf="item.anualpriceoferta > 0 && mostrarAnuales" style="color: red;"><sup><small>$</small></sup>{{item.anualpriceoferta}}/ <span class="font-16">Anual</span></h1>
              
              <div *ngIf="item.price > 0">
                <a href="{{item.url}}" class="btn btn-primary" *ngIf="!item.pruebaActiva">Obtener plan</a>
                <a href="{{item.url}}" class="btn btn-success" *ngIf="item.pruebaActiva">Prueba Gratis</a>
              </div>
              <div *ngIf="item.price == 0">
                <a href="{{item.url}}" class="btn btn-primary">Contactanos</a>
              </div>
              <div class="plan-features mt-5">
                <p>{{item.description}}</p>
                <p>Usuarios : <span class="text-success">{{item.usuarios}}</span></p>
                <p>Almacenes : <span class="text-success">{{item.almacenes}}</span></p>
                <p>Cajas : <span class="text-success">{{item.cajas}}</span></p>
                <p>Facturas : <span class="text-success">{{item.facturas}}</span></p>
                <p *ngFor="let modulo of item.modulos">
                  {{modulo.title}}
                  <span class='text-danger' *ngIf="!modulo.estado">
                    <i-feather name="x-circle" class="icons-sm mt-1 mr-2"></i-feather>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- Pricing end -->

  <!-- Cta start -->
  <section class="section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="text-center mb-5">
            <h5 class="text-primary text-uppercase small-title">Suscríbete</h5>
            <h4 class="mb-3">Suscríbete para recibir todas nuestras ofertas</h4>
            <!-- <p>It will be as simple as occidental in fact, it will be Occidental.</p> -->
          </div>
        </div>

        <div class="col-xl-8 col-lg-10">
          <div class="text-center">
            <div class="subscribe">
              <form>
                <div class="row">
                  <div class="col-md-9">
                    <div>
                      <input [(ngModel)]="email" name="email" class="form-control" placeholder="Ingresa tu correo electrónico">
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="mt-3 mt-md-0">
                      <button type="button" class="btn btn-primary btn-block" (click)="suscribirse();">Suscríbete</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- Cta end -->
