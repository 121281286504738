<!-- Footer start -->
<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-sm-6">
        <div>
          <h5 class="mb-4 footer-list-title">Sobre DomiSys</h5>
          <p>Domisys es un equipo de expertos en el desarrollo web. Ofrecemos un servicio personalizado, profesional y confiable.
            Trabajamos juntos para diseñar y crear herramientas para empresas y startups ambiciosas en las que creemos. </p>
        </div>
      </div>
      <div class="col-lg-2 offset-lg-1 col-sm-6">
        <div>
          <h5 class="mb-4 footer-list-title">Más información</h5>
          <ul class="list-unstyled footer-list-menu">
            <li><a href="https://puntoventa.domisys.com.do/account/select-edition">Precios</a></li>
            <li><a href="https://www.youtube.com/channel/UCz9yN40v-Qn2SkpPpYhj1aQ">Tutoriales</a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-2 col-sm-6">
        <div>
          <h5 class="mb-4 footer-list-title">Nuestras redes</h5>
          <ul class="list-unstyled footer-list-menu">
            <div class="media">
              <i-feather name="instagram" class="icon-dual-light icons-sm mt-1 mr-2"></i-feather>
              <div class="media-body">
                <p><a href="https://www.instagram.com/domisysdo" style="color: rgba(255, 255, 255, 0.5);">domisysdo</a></p>
              </div>
            </div>
            <div class="media">
              <i-feather name="facebook" class="icon-dual-light icons-sm mt-1 mr-2"></i-feather>
              <div class="media-body">
                <p><a href="https://www.facebook.com/domisys/" style="color: rgba(255, 255, 255, 0.5);">domisys</a></p>
              </div>
            </div>
            <div class="media">
              <i-feather name="youtube" class="icon-dual-light icons-sm mt-1 mr-2"></i-feather>
              <div class="media-body">
                <p><a href="https://www.youtube.com/channel/UCz9yN40v-Qn2SkpPpYhj1aQ" style="color: rgba(255, 255, 255, 0.5);">domisys</a></p>
              </div>
            </div>
          </ul>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div>
          <h5 class="mb-4 footer-list-title">Encuentranos</h5>
          <div>
            <div class="media">
              <i-feather name="map-pin" class="icon-dual-light icons-sm mt-1 mr-2"></i-feather>
              <div class="media-body">
                <p>C/ Real, Santiago de Los Caballeros, RD</p>
              </div>
            </div>
            <div class="media">
              <i-feather name="mail" class="icon-dual-light icons-sm mt-1 mr-2"></i-feather>
              <div class="media-body">
                <p><a href="mailto:ventas@domisys.com.do" style="color: rgba(255, 255, 255, 0.5);">ventas@domisys.com.do</a><br>
                  <a href="mailto:soporte@domisys.com.do" style="color: rgba(255, 255, 255, 0.5);">soporte@domisys.com.do</a></p>
              </div>
            </div>
            <div class="media">
              <i-feather name="phone" class="icon-dual-light icons-sm mt-1 mr-2"></i-feather>
              <div class="media-body">
                <p><a href="callto:(809) 252-2342" style="color: rgba(255, 255, 255, 0.5);">(809) 252-2342</a> <br>
                   <a href="callto:(829) 957-7602" style="color: rgba(255, 255, 255, 0.5);">(829) 957-7602</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->

</footer>
<!-- Footer end -->

<!-- Footer alt start -->
<section class="py-3" style="background-color: #0f1b42;">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="float-sm-left">
          <a href="javascript: void(0);">
            <img src="assets/images/logo-light.png" alt="" height="40">
          </a>
        </div>
        <div class="float-sm-right mt-4 mt-sm-0">
          <p class="copyright-desc text-white mb-0">{{year}} © Creado por DomiSys.</p>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</section>
<!-- Footer alt start -->
