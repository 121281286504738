<!-- Features start -->
<section class="section" id="features">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="text-center mb-5">
          <h5 class="text-primary text-uppercase small-title">Características</h5>
          <h4 class="mb-3">Características de nuestros productos</h4>
          <!-- <p>It will be as simple as occidental in fact, it will be Occidental.</p> -->
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-lg-5">
        <div>
          <div class="avatar-md mb-4">
            <span class="avatar-title rounded-circle bg-soft-primary">
              <i-feather name="tablet" class="icon-dual-primary"></i-feather>
            </span>
          </div>
          <h5>Diseño adaptable</h5>
          <p class="mb-4">Con nuestro sistema puedes gestionar tu negocio desde cualquier dispositivo.</p>

          <div class="row">
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Computadora
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Teléfono
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Tablet
              </p>
            </div>
          </div>

          <div class="mt-4">
            <a href="https://puntoventa.domisys.com.do/account/register-tenant?editionId=2&subscriptionStartType=2" 
                class="btn btn-primary">Aprender más <i-feather name="arrow-right"
                class="icons-sm ml-1">
              </i-feather></a>
          </div>
        </div>
      </div>

      <div class="col-lg-5 ml-lg-auto col-sm-8">
        <div class="card border border-light shadow-none mt-5 mt-lg-0">
          <div class="card-header border-0 bg-transparent">
            <div>
              <i class="mdi mdi-circle text-danger mr-1"></i>
              <i class="mdi mdi-circle text-warning mr-1"></i>
              <i class="mdi mdi-circle text-success mr-1"></i>
            </div>
          </div>
          <div class="card-body bg-light">
            <div class="box-shadow">
              <img src="assets/images/features/responsive.jpg" alt="" class="img-fluid mx-auto d-block">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row mt-5 pt-5">
      <div class="col-lg-5 col-sm-8">
        <div class="card border border-light shadow-none">
          <div class="card-header border-0 bg-transparent">
            <div>
              <i class="mdi mdi-circle text-danger mr-1"></i>
              <i class="mdi mdi-circle text-warning mr-1"></i>
              <i class="mdi mdi-circle text-success mr-1"></i>
            </div>
          </div>
          <div class="card-body bg-light">
            <div class="box-shadow">
              <img src="assets/images/features/dashboard.jpg" alt="" class="img-fluid mx-auto d-block">
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 ml-lg-auto">
        <div class="mt-4 mt-lg-0">
          <div class="avatar-md mb-4">
            <span class="avatar-title rounded-circle bg-soft-primary">
              <i-feather name="pie-chart" class="icon-dual-primary"></i-feather>
            </span>
          </div>
          <h5>Tablero de informaciones</h5>
          <p class="mb-4">Entérate de todo lo que pasa en tu negocio con una simple vista al tablero de informaciones.</p>

          <div class="row">
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Ventas
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Compras
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Cuentas por cobrar
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Y muchos más
              </p>
            </div>
          </div>

          <div class="mt-4">
            <a href="https://puntoventa.domisys.com.do/account/register-tenant?editionId=2&subscriptionStartType=2" 
                class="btn btn-primary">Aprender más <i-feather name="arrow-right"
                class="icons-sm ml-1">
              </i-feather></a>
          </div>
        </div>
      </div>

    </div>
    <!-- end row -->

    <div class="row mt-5 pt-5">
      <div class="col-lg-5">
        <div>
          <div class="avatar-md mb-4">
            <span class="avatar-title rounded-circle bg-soft-primary">
              <i-feather name="bar-chart-2" class="icon-dual-primary"></i-feather>
            </span>
          </div>
          <h5>Generación de reportes</h5>
          <p class="mb-4">Observa los reportes de tu empresa accediendo desde cualquier lugar del mundo.</p>

          <div class="row">
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Reportes de ganancias
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Reportes inventario
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Reportes transacciones
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Reportes fiscales
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Listados
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Y muchos más
              </p>
            </div>
          </div>

          <div class="mt-4">
            <a href="https://puntoventa.domisys.com.do/account/register-tenant?editionId=2&subscriptionStartType=2" 
                class="btn btn-primary">Aprender más <i-feather name="arrow-right"
                class="icons-sm ml-1">
              </i-feather></a>
          </div>
        </div>
      </div>

      <div class="col-lg-5 ml-lg-auto col-sm-8">
        <div class="card border border-light shadow-none mt-5 mt-lg-0">
          <div class="card-header border-0 bg-transparent">
            <div>
              <i class="mdi mdi-circle text-danger mr-1"></i>
              <i class="mdi mdi-circle text-warning mr-1"></i>
              <i class="mdi mdi-circle text-success mr-1"></i>
            </div>
          </div>
          <div class="card-body bg-light">
            <div class="box-shadow">
              <img src="assets/images/features/reports.jpg" alt="" class="img-fluid mx-auto d-block">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row mt-5 pt-5">
      <div class="col-lg-5 col-sm-8">
        <div class="card border border-light shadow-none">
          <div class="card-header border-0 bg-transparent">
            <div>
              <i class="mdi mdi-circle text-danger mr-1"></i>
              <i class="mdi mdi-circle text-warning mr-1"></i>
              <i class="mdi mdi-circle text-success mr-1"></i>
            </div>
          </div>
          <div class="card-body bg-light">
            <div class="box-shadow">
              <img src="assets/images/features/barcode.jpg" alt="" class="img-fluid mx-auto d-block">
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 ml-lg-auto">
        <div class="mt-4 mt-lg-0">
          <div class="avatar-md mb-4">
            <span class="avatar-title rounded-circle bg-soft-primary">
              <i-feather name="tag" class="icon-dual-primary"></i-feather>
            </span>
          </div>
          <h5>Códigos de barra</h5>
          <p class="mb-4">Maneja fácilmente tu facturación e inventario utilizando codigos de barra.</p>

          <div class="row">

          </div>

          <div class="mt-4">
            <a href="https://puntoventa.domisys.com.do/account/register-tenant?editionId=2&subscriptionStartType=2" 
                class="btn btn-primary">Aprender más <i-feather name="arrow-right"
                class="icons-sm ml-1">
              </i-feather></a>
          </div>
        </div>
      </div>

    </div>
    <!-- end row -->

    <div class="row mt-5 pt-5">
      <div class="col-lg-5">
        <div>
          <div class="avatar-md mb-4">
            <span class="avatar-title rounded-circle bg-soft-primary">
              <i-feather name="bar-chart-2" class="icon-dual-primary"></i-feather>
            </span>
          </div>
          <h5>Comprobantes fiscales</h5>
          <p class="mb-4">Genera automáticamente tus comprobantes fiscales y reporta.</p>

          <div class="row">
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Registro de secuencias
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Generación de comprobantes
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Reportes fiscales
              </p>
            </div>
          </div>

          <div class="mt-4">
            <a href="https://puntoventa.domisys.com.do/account/register-tenant?editionId=2&subscriptionStartType=2" 
                class="btn btn-primary">Aprender más <i-feather name="arrow-right"
                class="icons-sm ml-1">
              </i-feather></a>
          </div>
        </div>
      </div>

      <div class="col-lg-5 ml-lg-auto col-sm-8">
        <div class="card border border-light shadow-none mt-5 mt-lg-0">
          <div class="card-header border-0 bg-transparent">
            <div>
              <i class="mdi mdi-circle text-danger mr-1"></i>
              <i class="mdi mdi-circle text-warning mr-1"></i>
              <i class="mdi mdi-circle text-success mr-1"></i>
            </div>
          </div>
          <div class="card-body bg-light">
            <div class="box-shadow">
              <img src="assets/images/features/tax.jpg" alt="" class="img-fluid mx-auto d-block">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</section>
<!-- Features end -->
