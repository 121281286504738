const serviceData = [
    // { icon: 'grid', title: 'Bootstrap UI based', text: 'To an English person, it will seem like English as skeptical.' },
    // { icon: 'edit', title: 'Easy to customize', text: 'If several languages coalesce, the grammar of the language.' },
    // { icon: 'headphones', title: 'Awesome Support', text: 'The languages only differ in their grammar their pronunciation' },
    // { icon: 'layers', title: 'Creative Design', text: 'Everyone realizes why a new common would be desirable.' },
    // { icon: 'code', title: 'Quality Code', text: 'To achieve this, it would be necessary to have uniform.' },
    // { icon: 'tablet', title: 'Responsive layout', text: 'Their separate existence is a myth. For science, music, etc.' },
    { icon: 'tag', title: 'Sistema POS', text: 'Herramienta para administrar tu punto de venta.' },
    { icon: 'clipboard', title: 'Sistema financiero', text: 'Herramienta para prestamistas y para administrar tu financiera.' },
    { icon: 'headphones', title: 'Soporte técnico', text: 'Estamos disponibles para atender tus preguntas y agregar las funcionalidades que necesites.' },
    { icon: 'cloud', title: 'Herramientas en las nubes', text: 'Con nuestros sistemas en la nube no tienes que preocuparte por servidores.' },
    { icon: 'message-square', title: 'Chat', text: 'Chatea con tu equipo de trabajo y con nuestro equipo de soporte desde todas nuestras herramientas.' },
    { icon: 'hard-drive', title: 'Copias de respaldo', text: 'Con nuestros sistemas no tienes que preocuparte por las copias de respaldo, tu información está segura.' },
];

export { serviceData };
