const pricingData = [
    {
        title: 'Básico',
        description: 'Plan preparado para pequeños negocios de una sucursal',
        pruebaActiva: true,
        price: 7,
        anualprice: 70,
        priceoferta: 0,
        anualpriceoferta: 0,
        usuarios: '2',
        almacenes: '1',
        cajas: '1',
        facturas: '800',
        url: 'https://puntoventa.domisys.com.do/account/register-tenant?editionId=2&subscriptionStartType=2',
        modulos: [{title: 'Cajas', estado: true},
        {title: 'Facturacion', estado: true},
        {title: 'Inventario', estado: true},
        {title: 'Clientes', estado: true},
        {title: 'Dashboard', estado: true},
        {title: 'Cuentas por Cobrar', estado: false},
        {title: 'Cuentas por Pagar', estado: true},
        {title: 'Préstamos', estado: false}]
    },
    {
        title: 'Intermedio',
        description: 'Plan preferido, Ideal para puntos de venta medianos',
        pruebaActiva: false,
        price: 13,
        anualprice: 130,
        priceoferta: 0,
        anualpriceoferta: 0,
        usuarios: '5',
        almacenes: '2',
        cajas: '2',
        facturas: '1,400',
        url: 'https://puntoventa.domisys.com.do/account/register-tenant?editionId=3&subscriptionStartType=3&editionPaymentType=0',
        modulos: [{title: 'Cajas', estado: true},
        {title: 'Facturacion', estado: true},
        {title: 'Inventario', estado: true},
        {title: 'Clientes', estado: true},
        {title: 'Dashboard', estado: true},
        {title: 'Cuentas por Cobrar', estado: true},
        {title: 'Cuentas por Pagar', estado: true},
        {title: 'Préstamos', estado: false}]
    },
    {
        title: 'Avanzado',
        description: 'Plan preparado para puntos de ventas en expansión',
        pruebaActiva: false,
        price: 19,
        anualprice: 190,
        priceoferta: 0,
        anualpriceoferta: 0,
        usuarios: '12',
        almacenes: '4',
        cajas: '4',
        facturas: '2,500',        
        url: 'https://puntoventa.domisys.com.do/account/register-tenant?editionId=4&subscriptionStartType=3&editionPaymentType=0',
        modulos: [{title: 'Cajas', estado: true},
        {title: 'Facturacion', estado: true},
        {title: 'Inventario', estado: true},
        {title: 'Clientes', estado: true},
        {title: 'Dashboard', estado: true},
        {title: 'Cuentas por Cobrar', estado: true},
        {title: 'Cuentas por Pagar', estado: true},
        {title: 'Préstamos', estado: true}]
    },
    {
      title: 'Completo',
        description: 'Ideal para puntos de venta a gran escala que requieren un acceso ilimitado',
        pruebaActiva: false,
        price: 0,
        anualprice: 0,
        usuarios: 'Ilimitado',
        almacenes: 'Ilimitado',
        cajas: 'Ilimitado',
        facturas: 'Ilimitado',
        url: 'https://www.instagram.com/domisysdo',
        modulos: [{title: 'Cajas', estado: true},
        {title: 'Facturacion', estado: true},
        {title: 'Inventario', estado: true},
        {title: 'Clientes', estado: true},
        {title: 'Dashboard', estado: true},
        {title: 'Cuentas por Cobrar', estado: true},
        {title: 'Cuentas por Pagar', estado: true},
        {title: 'Préstamos', estado: true}]
    },
    {
      title: 'On Premises',
        description: 'Instala nuestro sistema en tus servidores y utilizalo de manera ilimitada',
        pruebaActiva: false,
        price: 0,
        anualprice: 0,
        usuarios: 'Ilimitado',
        almacenes: 'Ilimitado',
        cajas: 'Ilimitado',
        facturas: 'Ilimitado',
        url: 'https://www.instagram.com/domisysdo',
        modulos: [{title: 'Cajas', estado: true},
        {title: 'Facturacion', estado: true},
        {title: 'Inventario', estado: true},
        {title: 'Clientes', estado: true},
        {title: 'Dashboard', estado: true},
        {title: 'Cuentas por Cobrar', estado: true},
        {title: 'Cuentas por Pagar', estado: true},
        {title: 'Préstamos', estado: true}]
    },
    {
      title: 'Personalizado',
        description: 'Si no encuentras tu plan ideal, preparamos uno a la medida de tu empresa',
        pruebaActiva: false,
        price: 0,
        anualprice: 0,
        usuarios: 'Personalizado',
        almacenes: 'Personalizado',
        cajas: 'Personalizado',
        facturas: 'Personalizado',
        url: 'https://www.instagram.com/domisysdo',
        modulos: [{title: 'Cajas', estado: true},
        {title: 'Facturacion', estado: true},
        {title: 'Inventario', estado: true},
        {title: 'Clientes', estado: true},
        {title: 'Dashboard', estado: true},
        {title: 'Cuentas por Cobrar', estado: true},
        {title: 'Cuentas por Pagar', estado: true},
        {title: 'Préstamos', estado: true}]
    },
];

export { pricingData };
